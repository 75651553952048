<template>
  <div>
    <ManageWelcomePopUp />
  </div>
</template>
<script>
import ManageWelcomePopUp from "@/components/ManageWelcomePopUp/ManageWelcomePopUp";
export default {
  components: {
    ManageWelcomePopUp,
  },
  created() {},
};
</script>
