<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          จัดการภาพบรรยากาศ
        </span>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreateWelcomePopUp()" color="#2AB3A3" dark
              >เพิ่มภาพบรรยากาศ</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersBrandTh"
            :items="items"
            :search="search"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.img_url`]="{ item }">
              <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="UpdateNewsWelcomePopUp(item)">mdi-pencil</v-icon>
              <v-icon @click="ViewNewsWelcomePopUp(item)"
                >mdi-content-copy</v-icon
              >
              <v-icon @click="DeleteItemWelcomePopUp(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      headersBrandTh: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "ชื่อภาพ(ไทย)", value: "title_th", align: "start" },
        // { text: "คำอธิบาย(ไทย)", value: "desc_th", align: "start" },
        { text: "รูปภาพตัวอย่าง", value: "img_url", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      items: [],
      itemsComment: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAllWelcomePopUp();
  },
  methods: {
    async getAllWelcomePopUp() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/welcomePops`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAll", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async DeleteItemWelcomePopUp(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/welcomePops/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllWelcomePopUp();
        }
      });
    },

    goToCreateWelcomePopUp() {
      this.$router.push("CreateWelcomePopUp");
    },
    UpdateNewsWelcomePopUp(val) {
      localStorage.setItem("dataWelcomePopUp", Encode.encode(val));
      this.$router.push("EditWelcomePopUp");
    },
    ViewNewsWelcomePopUp(val) {
      localStorage.setItem("dataWelcomePopUp", Encode.encode(val));
      this.$router.push("ViewWelcomePopUp");
    },
  },
};
</script>